@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.MainDashboardMain {
  width: 100%;
  height: 100%;
  background-color: #edf4f7;
  box-sizing: border-box;
}
.Formulario-pedido {
  display: flex;
  flex-direction: column;
  max-width: 1500px;
}
.Formulario-pedido > container {
  flex: 1 1 200px;
}
@media (max-width: 100px) {
  .main {
    margin-top: 10%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .DataCards {
    flex-wrap: wrap;
    margin-left: 12%;
  }
  .sidebar {
    height: 50%;
  }
}
