@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.navBar {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: medium;
  box-sizing: border-box;
  justify-content: space-evenly;
  align-items: baseline;
  width: 100%;
}
