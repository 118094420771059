@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
.App {
    width: 100vw;
    height: 100vh;
    background-color: #f1f6f9;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    max-height: 100vh;
    max-width: 100vw;
    width: 100%;
    height: 100%;
}

