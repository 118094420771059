@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.MainClass {
    width: 100vw;
    height: 100vh;
    background-color: #f1f6f9;
    display: flex;
}

.loginInput {
    flex-wrap: wrap;
    max-width: 400px;
    align-items: center;
    margin-left: 40%;
    margin-right: 40%;
    align-items: center;
    font-family: 'Popins', sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    box-shadow: black;
}
.loginInput button {
    align-self: center;
    font-family: 'Roboto', sans-serif;
    background-color: #222831;
    box-shadow: #222831;
    cursor: pointer;
}
.loginInput h3 {
    align-self: center;
    text-align: center;
    font-family: 'Popins', sans-serif;
    font-weight: 500;
}

@media (max-width: 800px) {
    .loginInput {
        width: 1000px;
        height:1000px;
        max-width: 380px;
        margin-left: 2%;
        margin-right: 2%;
        align-items: center;
        font-family: 'Popins', sans-serif;
        font-weight: 500;
        box-sizing: border-box;
        box-shadow: black;
    }
}
