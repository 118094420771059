@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.Main{
    font-family: 'Roboto';
    box-sizing: border-box;
    font-weight: 600;
    font-size: medium;
    margin-top: auto 0;
}